body {
    padding: 3em;
}

#appRoot * {
    font-size: 18px;
}

header {
    padding: 27px 0;
}

h1 {
    margin-bottom: 27px;
}

h4 {
    margin-bottom: 16px;
}